var DrawerSelect = Ajax.extend({

    $_inputCheckboxDrawer: null,
    $_addToFavoritesButton: null,

    _currentUrl: '',
    _activeDrawer: null,

    init: function() {

        // Select elements
        this.$_inputCheckboxDrawer = $('input[name=drawer_select]');
        this.$_addToFavoritesButton = $('a#add-to-favorites');

        // Parse the current url
        this._parseCurrentUrl();

        // If theres a drawer active, make it active
        if (this._activeDrawer !== null) {
            this._setDrawerToActive();
            if (this.$_addToFavoritesButton.length > 0) {
                this._updateAddToFavoritesButton();
            }
        }

        // Handle drawer clicks
        //$('body').on('click', 'a.remove', $.proxy(this.onRemoveClick, this));
        this.$_inputCheckboxDrawer.on('click', $.proxy(this.onCheckboxClick, this));
    },

    onCheckboxClick: function(e) {
        var $target = $(e.currentTarget);

        this._activeDrawer = null;
        if ($target.is(':checked')) {
            this._activeDrawer = $target.val();
        }

        var newUrl = this._currentUrl;
        if (this._activeDrawer !== null) {
            newUrl += '/drawer:' + this._activeDrawer;
        }

        if (this.$_addToFavoritesButton.length > 0) {
            this._updateAddToFavoritesButton();
        }
        this.insertUrl(newUrl);
    },

    _setDrawerToActive: function() {
        var $drawerCheckbox = $('input[value=' + this._activeDrawer + ']');
        $drawerCheckbox.attr('checked', true);
    },

    _updateAddToFavoritesButton: function() {
        var url = this._parseAddToFavoritesUrl(this.$_addToFavoritesButton.attr('href'));
        this.$_addToFavoritesButton.attr('href', url);
    },

    _parseAddToFavoritesUrl: function(currentUrl) {
        if (this._activeDrawer === null) {
            var splitUrl = currentUrl.split('.');
            splitUrl.pop();
            currentUrl = splitUrl.join('.');
        } else {
            if (currentUrl.indexOf('.extra:') > -1) {
                var splitUrl = currentUrl.split(':');
                var drawerId = splitUrl.pop();
                drawerId = this._activeDrawer;
                var currentUrl = splitUrl.join(':') + ':' + drawerId;
            } else {
                currentUrl += '.extra:' + this._activeDrawer;
            }
        }
        return currentUrl;
    },

    _parseCurrentUrl: function() {

        var parseUrl = window.location.pathname;
        var urlSegments = parseUrl.split('/');

        for (var i in urlSegments) {
            if (urlSegments[i] !== '') {
                if (urlSegments[i].indexOf('drawer:') > -1) {
                    var drawerSegment = urlSegments[i].split(':');
                    this._activeDrawer = drawerSegment[1];
                } else {
                    this._currentUrl += '/' + urlSegments[i];
                }
            }
        }
    }

});