var Filters = Ajax.extend({

	$_form 					: null,
	$_doors 				: null,
	$_selectedFilters		: null,

	$_appliedMouldingSs 	: null, 
	$_constructionTypeSs	: null,
	$_edgeDetailsSs 		: null,
	$_familiesSs 			: null,
	$_materialsSs 			: null,
	$_miterMoldingsSs		: null,
	$_raisedPanelProfilesSs : null,
	$_stileRailProfilesSs 	: null,
	$_priceRangeSs			: null,
	$_doorStylesSs			: null,
	$_optionsSs				: null,
	$_numFound				: null,

	$_productsListing       : null,

	_parsed                 : null,
	_preSegmentsFiltered	: '/doors/filter/',
	_preSegmentsNoFilter	: '/doors',
	_removeList				: [],
	_scroll					: null,

	init: function(scroll) {

		this._super();

		this.$_form 			     = $('form#filters');
		this.$_doors                 = $('div#doors');
		this.$_selectedFilters		 = $('ul.filters');

		this.$_appliedMouldingSs     = $('#applied_moulding_ss');
		this.$_constructionTypeSs    = $('#construction_type_ss');
		this.$_edgeDetailsSs         = $('#edge_details_ss');
		this.$_familiesSs            = $('#families_ss');
		this.$_materialsSs           = $('#materials_ss');
		this.$_miterMoldingsSs       = $('#miter_moldings_ss');
		this.$_raisedPanelProfilesSs = $('#raised_panel_profiles_ss');
		this.$_stileRailProfilesSs   = $('#stile_rail_profiles_ss');
		this.$_priceRangeSs			 = $('#price_ss');
		this.$_doorStylesSs			 = $('#door_styles_ss');
		this.$_optionsSs			 = $('#options_ss');
		this.$_numFound				 = $('#numfound');

		// Reference the scroll system.
		this.$_productsListing 		 = $('.productsListing');

		this.$_productsListing.jscroll({
            loadingHtml: '',
            nextSelector: 'a.nextPage:last'
        });

		$(this).on(this.AJAXLOADED, 	   $.proxy(this.dataLoaded, this));
		$(this).on(this.AJAXHISTORYCHANGE, $.proxy(this.historyChange, this));

		var pathName = window.location.pathname.replace(this._preSegments, '');
		if (pathName !== '' && pathName !== '/doors') {
			this._parsed = this._parsePathName(pathName);
			this._checkCheckboxes();
			this._addRemoveButton();
			this._updateCountBadges();
		}

		this.showFilters();
		this.$_form.on('click', 'input[type=checkbox]', $.proxy(this.onCheckboxClick, this));
	},

	showFilters: function() {

		$('ul.cb').show();
		$('div#numfound').show();

	},

	_updateCountBadges: function() {

		var $countBadge = $('.countBadge').html('');

		for (var key in this._parsed) {
			var $countBadge = $('.countBadge.' + key);
			var length = this._parsed[key].length;
			$countBadge.html(this._parsed[key].length);
		}

	},

	onCheckboxClick: function(e) {

		//this.closeFilters();

		var $target = $(e.currentTarget);
		var checked = $target.is(':checked');

		if (!checked) {
			this._removeFromRemoveList($target.val());
		}

		this._handleForm();

		$checkboxes = $('input[type=checkbox]');
		$checkboxes.prop('disabled', 'disabled');

	},

	closeFilters: function() {
        // Selections
        var $openedFilters = $('.vis-icon.fa-minus');
        var $fieldSet = $openedFilters.parents().eq(3).find('.fieldset');

        // Switch the minus to a plus again
        $openedFilters.toggleClass('fa-plus fa-minus');

        // Find the fieldset to collapse it again
        $fieldSet.slideToggle();
    },

	_handleForm: function() {
		var serialized = this.$_form.serialize();
		this._parsed   = this._parseSerialized(serialized);

		var length = 0;
		for (var key in this._parsed) {
			length++;
		}

		var url = (length ? this._preSegmentsFiltered : this._preSegmentsNoFilter) + this._parsedToUrl(this._parsed);

		this.insertUrl(url);
		this._addRemoveButton();
		this._updateCountBadges();
	},

	_removeFromRemoveList: function(slug) {

		for (var i in this._removeList) {
			if (slug === this._removeList[i].slug) {
				this._removeList.splice(i, 1);
			}
		}

	},

	_addRemoveButton: function() {

		var active = this._getActiveFilters();

		for (var i in active) {
			var add = true;
			for (var j in this._removeList) {
				if (this._removeList[j].slug !== undefined && 
					active[i] === this._removeList[j].slug) {
					add = false;
					break;
				}
			}
			if (add) {

				$filterButton = $('input[value=' + active[i] + ']');
				var data = $filterButton.attr('data-title');
				this._removeList.push({slug: active[i], value: data});
				//break;
			}
		}

		this._drawRemoveButtons();
	},

	_drawRemoveButtons: function() {

		this.$_selectedFilters.find('li').remove();

		for (var i in this._removeList) {
			var $filter = $('<li />').html('<a href="' +  this._removeList[i].slug + '"><i class="fa fa-times-circle"></i> ' + this._removeList[i].value + '</a>');
			$filter.on('click', $.proxy(this.onRemoveFilterClick, this));
			this.$_selectedFilters.append($filter);
		}
	},

	onRemoveFilterClick: function(e) {
		e.preventDefault();

		// Fetch what category should be disabled
		var $target = $(e.currentTarget).find('a');
		var remove = $target.attr('href');
		
		// Uncheck filter
		var $filter = $('input[type=checkbox][value=' + remove + ']');
		$filter.attr('checked', false);

		this._removeFromRemoveList(remove);
		this._drawRemoveButtons();

		// Voilla
		this._handleForm();
	},

	dataLoaded: function(e, data) {

		// Get the ajax response and convert it to a "regular" jquery object
		// so we can make normal selections to it.
		var $response = $('<html />').html(data.ajaxdata);

		// Recheck checkboxes
		this._checkCheckboxes($response);
		
		// Fetch stuff we need from the response
		var $doors 				   = $response.find('div.productBox'),

			// Filters
			$appliedMouldingSs     = $response.find('ul#applied_moulding_ss').children(),
			$constructionTypeSs    = $response.find('ul#construction_type_ss').children(),
			$edgeDetailsSs 		   = $response.find('ul#edge_details_ss').children(),
			$familiesSs 		   = $response.find('ul#families_ss').children(),
			$materialsSs 		   = $response.find('ul#materials_ss').children(),
			$miterMoldingsSs	   = $response.find('ul#miter_moldings_ss').children(),
			$raisedPanelProfilesSs = $response.find('ul#raised_panel_profiles_ss').children(),
			$stileRailProfilesSs   = $response.find('ul#stile_rail_profiles_ss').children(),
			$priceRangeSs		   = $response.find('ul#price_ss').children(),
			$doorStylesSs          = $response.find('ul#door_styles_ss').children(),
			$optionsSs      	   = $response.find('ul#options_ss').children(),

			// Numfound
			$numFound   		   = $response.find('div#numfound').children(),

			// Autoload
			$nextLink              = $response.find('a.nextPage:last');

		
		// Clear filter containers 
		this.$_doors.children().remove(); // Doors
		this.$_appliedMouldingSs.children().remove(); // Facets
		this.$_constructionTypeSs.children().remove(); // Facets
		this.$_edgeDetailsSs.children().remove(); // Facets
		this.$_familiesSs.children().remove(); // Facets
		this.$_materialsSs.children().remove(); // Facets
		this.$_miterMoldingsSs.children().remove(); // Facets
		this.$_raisedPanelProfilesSs.children().remove(); // Facets
		this.$_stileRailProfilesSs.children().remove(); // Facets
		this.$_priceRangeSs.children().remove(); // Facets
		this.$_doorStylesSs.children().remove(); // Facets
		this.$_optionsSs.children().remove(); // Facets
		this.$_numFound.children().remove(); // Numfound

		// And add them again
		// 
		var $jScroll = $('<div />').addClass('jscroll-inner').append($doors);
		$jScroll.append($nextLink);
		this.$_doors.append($doors); // Doors
		this.$_doors.append($nextLink);

		this.$_appliedMouldingSs.append($appliedMouldingSs); // Facets
		this.$_constructionTypeSs.append($constructionTypeSs); // Facets
		this.$_edgeDetailsSs.append($edgeDetailsSs); // Facets
		this.$_familiesSs.append($familiesSs); // Facets
		this.$_materialsSs.append($materialsSs); // Facets
		this.$_miterMoldingsSs.append($miterMoldingsSs); // Facets
		this.$_raisedPanelProfilesSs.append($raisedPanelProfilesSs); // Facets
		this.$_stileRailProfilesSs.append($stileRailProfilesSs); // Facets
		this.$_priceRangeSs.append($priceRangeSs); // Facets
		this.$_doorStylesSs.append($doorStylesSs); // Facets
		this.$_optionsSs.append($optionsSs); // Facets
		this.$_numFound.append($numFound); // Numfound

		this.$_productsListing.unbind().removeData()
		this.$_productsListing.jscroll({
            loadingHtml: '',
            nextSelector: 'a.nextPage:last'
        });
	},

	_getActiveFilters: function() {

		var activeFilters = [];

		for (var key in this._parsed) {
			for (var i in this._parsed[key]) {
				activeFilters.push(this._parsed[key][i]);
			}
		}

		return activeFilters;

	},

	_parsePathName: function(pathName) {

		if (pathName.indexOf('/') > -1) {
			pathName = pathName.split('/').pop();
		}

		var phaseOne = pathName.split('.');
		var result = [];

		for (var i in phaseOne) {

			var value = phaseOne[i].split(':');

			if (result[value[0]] === undefined) {
				result[value[0]] = [];
			}

			result[value[0]] = value[1].split(',');
		}

		return result;

	},

	_checkCheckboxes: function($response) {

		$checkboxes = $('input[type=checkbox]');
		$checkboxes.attr('disabled', null);

		for (var key in this._parsed) {
			for (var i in this._parsed[key]) {

				var selector = decodeURIComponent(this._parsed[key][i]).replace(/\+/g, ' ').trim();
				var $checkbox;

				if ($response === undefined) {
					$checkbox = $('input[value="' + selector + '"]');
				} else {
					$checkbox = $response.find('input[value="' + selector + '"]');
				}
				
				$checkbox.prop('checked', true);
			}
		}
	},

	_parseSerialized: function(serialized) {

		var phaseOne = [];
		if (serialized !== '') {
			phaseOne = serialized.split('&');
		}
		var result = [];

		for (var i in phaseOne) {

			var value = phaseOne[i].split('=');

			if (result[value[0]] === undefined) {
				result[value[0]] = [];
			}

			result[value[0]].push(value[1]);
		}

		return result;
	},

	_parsedToUrl: function(parsed) {

		var url = '';
		for (var key in parsed) {
			url += '.' + key + ':';
			url += parsed[key].join(',');
		}

		return url.substring(1);

	}

});

