var DoorPriceCalculator = Class.extend({

    $selectMaterial: null,
    $txtPriceSqFt: null,
    $txtPriceWidth: null,
    $txtPriceHeight: null,
    $txtDoorPrice: null,
    $minSqFt: null,

    init: function() {

        // Elements
        this.$selectMaterial = $('select#doorMaterial');
        this.$txtPriceSqFt   = $('input#priceSqFt');
        this.$txtPriceWidth  = $('input#priceWidth');
        this.$txtPriceHeight = $('input#priceHeight');
        this.$txtDoorPrice   = $('input#priceDoorPrice');
        this.$minSqFt        = $('span.minSqFt');

        // Events
        this.$selectMaterial.on('change', $.proxy(this.selectMaterialChange, this));
        this.$txtPriceWidth.on('change keyup paste', $.proxy(this.calcDoorPrice, this));
        this.$txtPriceHeight.on('change keyup paste', $.proxy(this.calcDoorPrice, this));
    },


    selectMaterialChange: function(e) {
        e.preventDefault();

        var $target = $(e.target);

        var val = parseFloat($target.val());
        this.$txtPriceSqFt.val('$' + val.toFixed(2));
        //console.log($target.val());

        this.calcDoorPrice(true);
    },

    calcDoorPrice: function(selectChange) {
        var minSqFt            = this.$minSqFt.text(),
            pricePerSquareFeet = parseFloat(this.$txtPriceSqFt.val().replace(/[^\d.]/g,'')),
            width              = parseFloat(this.$txtPriceWidth.val()),
            height             = parseFloat(this.$txtPriceHeight.val()),
            a                  = (width * height) / 144;

        if(isNaN(a) || isNaN(pricePerSquareFeet) || isNaN(minSqFt)) {
            this.$txtDoorPrice.val('');
            if (selectChange === true) {
                this.$txtPriceWidth.val('');
                this.$txtPriceHeight.val('');
            }
            return;
        }

        if(a<minSqFt) {
            a = minSqFt;
        }

        var result = pricePerSquareFeet * a;
        this.$txtDoorPrice.val('$' + parseFloat(result).toFixed(2));
    }


});