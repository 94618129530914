var LoginModal = Class.extend({

    $form: null,
    $errorMessages: null,

    init: function() {

        this.$form = $('div#loginModal form');
        this.$errorMessages = $('div#errorMessages');

        this.$form.on('submit', $.proxy(this.onFormSubmit, this));
    },

    onFormSubmit: function(e) {
        e.preventDefault();

        var postData = this.$form.serialize();

        $.ajax({
            url: '',
            type: 'POST',
            data: postData,
            success: $.proxy(function(data, textStatus, jqXHR)
            {
                if (data.error !== undefined) {
                    var $errorMessage = $('<p />').text(data.error);
                    this.$errorMessages.children().remove();
                    this.$errorMessages.append($errorMessage);
                } else {
                    window.location.reload();
                }
                
            }, this)
        });
    }

});