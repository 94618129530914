var AddToFavorites = Class.extend({

    $_addToFavoritesButton: null,
    $_removeFromFavoritesButton: null,
    $_favButtonsContainer: null,
    $_drawer: null,

    favLocation: null,

    _entryId: null,

    init: function(favPage) {

        var pathname = window.location.pathname;

        if (pathname.indexOf('drawers') > 0) {
            this.favLocation = 'drawers';
        }

        if (pathname.indexOf('doors') > 0) {
            this.favLocation = 'doors';
        }

        this.$_removeFromFavoritesButton = $('a.remove-from-favorites');
        if (favPage !== undefined && favPage) {
            this.$_removeFromFavoritesButton.on('click', $.proxy(this.onRemoveFromFavoritesClickOnFavPage, this));
        } else {
            this.$_drawer = $('div.drawer');
            this.$_addToFavoritesButton = $('a#add-to-favorites');
            this.$_favButtonsContainer  = $('div#favButtons');
            this.$_addToFavoritesButton.on('click', $.proxy(this.onAddToFavoritesClick, this));
            this.$_removeFromFavoritesButton.on('click', $.proxy(this.onRemoveFromFavoritesClick, this));
            this.$_drawer.on('click', $.proxy(this.onDrawerClick, this));
        }
    },

    onDrawerClick: function(e) {

        this.$_addToFavoritesButton.show();
    },

    onAddToFavoritesClick: function(e) {
        e.preventDefault();
        
        var $target = $(e.currentTarget);
        var url = $target.attr('href'); 
        
        $.getJSON(url, $.proxy(function(data) {
            if (this.favLocation === 'drawers') {
                this.$_addToFavoritesButton.hide();
            } else {
                this.$_addToFavoritesButton.hide();
                this.$_addToFavoritesButton.html('Update favorite');
            }
            this.$_removeFromFavoritesButton.show();
        }, this));
    },

    onRemoveFromFavoritesClick: function(e) {
        e.preventDefault();

        var $target = $(e.currentTarget);
        var url = $target.attr('href');

        $.getJSON(url, $.proxy(function(data) {
            if (this.favLocation === 'drawers') {
                this.$_addToFavoritesButton.show();
            } else {
                this.$_addToFavoritesButton.show();
                this.$_addToFavoritesButton.html('Add to favorites');
            }
            this.$_removeFromFavoritesButton.hide();
        }, this));
    },

    onRemoveFromFavoritesClickOnFavPage: function(e) {
        e.preventDefault();

        var $target = $(e.currentTarget);
        var url = $target.attr('href');
        var $table = $('table');

        $.getJSON(url, $.proxy(function(data) {
            $target.parent().parent().remove();
            var tableRowCount = $table.find('tr').length;
            if (tableRowCount === 0) {
                $table.append('<tr><td>No favorites.</td></tr>');
            }
        }, this));
    }

});