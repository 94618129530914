/*!
 * Ajax base
 * Author: Dion Snoeijen
 * Generic Ajax Helper Functionality
 * @type {*|extend}
 */
var Ajax = Class.extend({

    // -------------------------
    //	Constructor
    // -------------------------
    init: function(listenOn) {
        //this._super();

        this.AJAXLOADED 	   = 'ajaxloaded';
        this.AJAXCLICK 		   = 'ajaxclick';
        this.AJAXHISTORYCHANGE = 'ajaxhistorychange';
        
        this.bStateChangedByHistory = true;
        this.$xhr = null;

        if (listenOn !== undefined) {
        	// Set event listeners
        	$('body').on('click', listenOn, $.proxy(this.__aClick, this));
    	}
        
        // Bind to State Change
        History.Adapter.bind(window, 'statechange', $.proxy(this.__windowStateChange, this));
    },

    // -------------------------
    //	Private Methods
    // -------------------------
    _fetchPage: function(url) {
        if(this.$xhr != null) {
            this.$xhr.abort();
        }
        
        this.$xhr = $.ajax(url);
        this.$xhr.done($.proxy(function(data) {
            $(this).trigger(this.AJAXLOADED, [{ajaxdata:data}]);
        }, this));
        this.$xhr.fail($.proxy(function() {
            console.log('XHR FAILED');
        }, this));
    },

    // -------------------------
    //	Public Methods
    // -------------------------
    insertUrl: function(url) {

    	if(url !== '#') {
    		History.pushState({state:1}, "Design Craft Door", url);
    	}

    	this.bStateChangedByHistory = false;
    },

    // -------------------------
    //	Events
    // -------------------------
    __aClick: function(e) {
        var $target = $(e.currentTarget);
        var url = $target.attr('href');

        if(url !== '#') {
            e.preventDefault();
            e.stopImmediatePropagation();

            History.pushState({state:1}, "Design Craft Door", url);
        }

        $(this).trigger(this.AJAXCLICK, [{target:$target}]);
        
        this.bStateChangedByHistory = false;
    },

    __windowStateChange: function(e) {
        
        // Log the State
        var State = History.getState();
        this._fetchPage(State.url);
        
        setTimeout(function() {
            $(this).trigger(this.AJAXHISTORYCHANGE, this.bStateChangedByHistory);
            this.bStateChangedByHistory = true;
        }.bind(this), 200);
    }

});