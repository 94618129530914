var SelectedTab = Class.extend({

    $firstTab: null,

    init: function() {

        this.$firstTab = $('div.tabsContainer > ul.tabs > li:nth-child(1)');
        this.$firstTab.find('a').addClass('selected');

    }

});