var Scroll = Class.extend({

    init: function() {

        var $productsListing = $('.productsListing');
        var $nextPage = $('a.nextPage:last');

        if ($nextPage.length > 0) {
            // console.log('INIT SCROLL');
            // console.log($productsListing);
            $productsListing.jscroll({
                loadingHtml: '',
                nextSelector: 'a.nextPage:last'
            });
        }

    }

});