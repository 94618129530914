var FilterCollapse = Class.extend({

    $_productFiltersLegend: null,
    $_updateFilters: null,

    init: function() {

        this.$_productFiltersLegend = $('.productFilters span.legend');
        this.$_updateFilters = $('#updateFilters');

        this.$_productFiltersLegend.on('click', $.proxy(this.onLegendClick, this));
        this.$_updateFilters.on('click', $.proxy(this.onUpdateFiltersClick, this));
    },

    onLegendClick: function(e) {
        e.preventDefault();

        // Selections
        var $target = $(e.target);

        if ($target.is("i")) {
            $target = $target.parent();
        }
        
        var $visIcon = $target.find('.vis-icon');
        var $fieldSet = $target.parents().eq(2).find('.fieldset');

        // Close opened filter blocks
        if ($visIcon.hasClass('fa-plus')) {
            this.closeFilters();
        }

        // Toggle the class
        $visIcon.toggleClass('fa-plus fa-minus');
        
        // Toggle the fieldset
        $fieldSet.slideToggle();
    },

    onUpdateFiltersClick: function(e) {
        e.preventDefault();

        $('.showFilters a').click();
        // Close opened filter block
        this.closeFilters();
    },

    closeFilters: function() {
        // Selections
        var $openedFilters = $('.vis-icon.fa-minus');
        var $fieldSet = $openedFilters.parents().eq(3).find('.fieldset');

        // Switch the minus to a plus again
        $openedFilters.toggleClass('fa-plus fa-minus');

        // Find the fieldset to collapse it again
        $fieldSet.slideToggle();
    }

});