var DoorSizeCalculatorModel = Class.extend({
    // Query types
    APPLIED_MOULDINGS: 'applied-mouldings',
    MITER_MOULDINGS: 'miter-mouldings',
    DOOR_STYLE: 'door-style',
    MIN_VALUES: 'min-values',
    MIN_WIDTH: 'min-width',
    RAISED_PANEL_PROFILES: 'raised-panel-profiles',

    // Store request
    productIdentity: null,
    productFieldIds: null,
    queryCaller: null,

    minValues: [],
    raisedPanelProfiles: [],

    root: '/',

    init: function() {},

    // -------------------------
    // Queries
    // -------------------------

    queryDoorStyle: function() {
        var request =
            this.root + 'handlers/door-size-calculator/' + this.DOOR_STYLE;

        $.getJSON(
            request,
            $.proxy(function(result) {
                var resultDoorStyle = [];

                $.each(result.data, function(index, data) {
                    data.DisplayOrder = Number(data.DisplayOrder);
                    resultDoorStyle['key-' + data.ProdIdentity] = data;
                });

                resultDoorStyle.sort(function(a, b) {
                    if (a.DisplayOrder < b.DisplayOrder) {
                        return -1;
                    } else if (a.DisplayOrder > b.DisplayOrder) {
                        return 1;
                    }
                    return 0;
                });

                $(this).trigger({
                    type: 'doorStylesLoaded',
                    resultDoorStyle: resultDoorStyle,
                });
            }, this)
        );
    },

    getAppliedMouldings: function() {
        var request =
            this.root +
            'handlers/door-size-calculator/' +
            this.APPLIED_MOULDINGS;

        $.getJSON(
            request,
            $.proxy(function(result) {
                // result.data.sort(function (a, b) {
                //     if (a.DisplayOrder < b.DisplayOrder) {
                //         return -1;
                //     } else if (a.DisplayOrder > b.DisplayOrder) {
                //         return 1;
                //     }
                //     return 0;
                // });

                $(this).trigger({
                    type: 'appliedMouldingsLoaded',
                    resultAppliedMouldings: result.data,
                });
            }, this)
        );
    },

    getMiterMouldings: function() {
        var request =
            this.root + 'handlers/door-size-calculator/' + this.MITER_MOULDINGS;

        $.getJSON(
            request,
            $.proxy(function(result) {
                $(this).trigger({
                    type: 'miterMouldingsLoaded',
                    resultMiterMouldings: result.data,
                });
            }, this)
        );
    },

    getRaisedPanelProfiles: function(queryCaller) {
        var request =
            this.root +
            'handlers/door-size-calculator/' +
            this.RAISED_PANEL_PROFILES;

        var eventObject = {
            type: 'raisedPanelProfilesLoaded',
            queryCaller: queryCaller,
        };

        if (this.raisedPanelProfiles[request] === undefined) {
            $.getJSON(
                request,
                $.proxy(function(result) {
                    // result.data.sort(function (a, b) {
                    //     if (a.Description < b.Description) {
                    //         return 1;
                    //     } else if (a.Description > b.Description) {
                    //         return -1;
                    //     }
                    //     return 0;
                    // });

                    //console.log('raised panel profiles', result.data);

                    eventObject.resultRaisedPanelProfiles = result.data;
                    $(this).trigger(eventObject);
                }, this)
            );
        }
    },

    getMinValues: function(productIdentity, productFieldIds, queryCaller) {
        var request =
            this.root +
            'handlers/door-size-calculator/' +
            this.MIN_VALUES +
            '/' +
            productIdentity;

        for (var i in productFieldIds) {
            request += '.' + productFieldIds[i];
        }

        var eventObject = {
            type: 'minValuesLoaded',
            productIdentity: productIdentity,
            productFieldIds: productFieldIds,
            queryCaller: queryCaller,
        };

        if (this.minValues[request] === undefined) {
            $.getJSON(
                request,
                $.proxy(function(result) {
                    if (result.data_status) {
                        eventObject.resultMinValues = this._parseMinValues(
                            result.data
                        );
                    } else {
                        eventObject.resultMinValues = false;
                    }

                    this.minValues[request] = eventObject.resultMinValues;
                    $(this).trigger(eventObject);
                }, this)
            );
        } else {
            eventObject.resultMinValues = this.minValues[request];
            $(this).trigger(eventObject);
        }
    },

    // -------------------------
    // Private
    // -------------------------

    _parseMinValues: function(data) {
        var result = [];

        for (var i in data) {
            if (result['pfid-' + data[i].ProductFieldId] === undefined) {
                result['pfid-' + data[i].ProductFieldId] = [];
            }

            result['pfid-' + data[i].ProductFieldId].push(data[i]);
        }

        return result;
    },
});

/**
 * This is a somewhat direct port of the VBA macro created for the access tables.
 *
 * // W4181 has miter moulding pulldown
 */
var DoorSizeCalculator = Class.extend({
    model: null,

    // Door types
    SLAB: 'slab',
    MITER: 'miter',
    MITER_WITH_RAISED_PANEL_DOOR_STYLE: 'miter-with-raised-panel-door-style',
    APPLIED_MOULDING: 'applied-moulding',
    APPLIED_MOULDING_WITH_RAISED_PANEL_DOOR_STYLE:
        'applied-moulding-with-raised-panel-door-style',
    STANDARD_COPE_AND_STICK: 'standard-cope-and-stick',
    STANDARD_COPE_AND_STICK_WITH_RAISED_PANEL_DOOR_STYLE:
        'standard-cope-and-stick-with-raised-panel-door-style',

    // Regexes
    regexMiterWithRaisedPanelDoorStyle: 'W41?[0,3]',
    regexAppliedMoulding: 'W[1,5]8',
    regexCopeAndStick: 'W[1,5][1-9]',
    regexAppliedMouldingWithRaisedPanelDoorStyle: 'W180[0,3]',
    regexAlternateAppliedMouldingWithRaisedPanelDoorStyle: 'W[1][1-9]?[0,3]',
    regexEndsWithOneOrTwo: '[0-9]+',

    // Door style
    selectedDoorStyle: null,

    // Data
    resultDoorStyle: [],
    elementSelectors: [],
    calculationValues: null,

    // Selectors
    selectDoorStyleOptions: 'select#door-style-options',
    selectRaisedPanelOptions: 'select#raised-panel-options',
    selectAppliedMouldingOptions: 'select#applied-moulding-options',
    selectMiterMouldingOptions: 'select#miter-moulding-options',
    txtPanelsWide: 'input#panels-wide',
    txtPanelsHigh: 'input#panels-high',
    txtTopRailWidth: 'input#top-rail-width',
    txtBottomRailWidth: 'input#bottom-rail-width',
    txtLeftStileWidth: 'input#left-stile-width',
    txtRightStileWidth: 'input#right-stile-width',
    txtCenterStileWidth: 'input#center-stile-width',
    txtCenterRailWidth: 'input#center-rail-width',
    txtMinWidth: 'td#min-width',
    txtMinHeight: 'td#min-height',
    buttonCalc: 'button.button',

    // Elements
    $selectDoorStyleOptions: null,
    $selectRaisedPanelOptions: null,
    $selectAppliedMouldingOptions: null,
    $selectMiterMouldingOptions: null,
    $txtPanelsWide: null,
    $txtPanelsHigh: null,
    $txtTopRailWidth: null,
    $txtBottomRailWidth: null,
    $txtLeftStileWidth: null,
    $txtRightStileWidth: null,
    $txtCenterStileWidth: null,
    $txtCenterRailWidth: null,
    $txtMinWidth: null,
    $txtMinHeight: null,
    $buttonCalc: null,

    // Hash
    hash: null,

    init: function(model) {
        this.model = model;

        this.elementSelectors = [
            { element: this.selectDoorStyleOptions, set: false },
            { element: this.selectRaisedPanelOptions, set: false },
            { element: this.selectAppliedMouldingOptions, set: false },
            { element: this.selectMiterMouldingOptions, set: false },
            { element: this.txtPanelsWide, set: false },
            { element: this.txtPanelsHigh, set: false },
            { element: this.txtTopRailWidth, set: false },
            { element: this.txtBottomRailWidth, set: false },
            { element: this.txtLeftStileWidth, set: false },
            { element: this.txtRightStileWidth, set: false },
            { element: this.txtCenterStileWidth, set: false },
            { element: this.txtCenterRailWidth, set: false },
            { element: this.txtMinWidth, set: false },
            { element: this.txtMinHeight, set: false },
            { element: this.buttonCalc, set: false },
        ];

        var href = $('a.calcDoorSize').attr('href');
        if (href !== undefined) {
            href = href.split('#');
            if (href[1] !== undefined) {
                this.hash = href[1].toLowerCase();
            }
        } else {
            this.hash = window.location.hash.substring(1).toLowerCase();
        }

        // Elements
        this.$selectDoorStyleOptions = $(this.selectDoorStyleOptions);
        this.$selectRaisedPanelOptions = $(this.selectRaisedPanelOptions);
        this.$selectAppliedMouldingOptions = $(
            this.selectAppliedMouldingOptions
        );
        this.$selectMiterMouldingOptions = $(this.selectMiterMouldingOptions);
        this.$txtPanelsWide = $(this.txtPanelsWide);
        this.$txtPanelsHigh = $(this.txtPanelsHigh);
        this.$txtTopRailWidth = $(this.txtTopRailWidth);
        this.$txtBottomRailWidth = $(this.txtBottomRailWidth);
        this.$txtLeftStileWidth = $(this.txtLeftStileWidth);
        this.$txtRightStileWidth = $(this.txtRightStileWidth);
        this.$txtCenterStileWidth = $(this.txtCenterStileWidth);
        this.$txtCenterRailWidth = $(this.txtCenterRailWidth);
        this.$txtMinWidth = $(this.txtMinWidth);
        this.$txtMinHeight = $(this.txtMinHeight);
        this.$buttonCalc = $(this.buttonCalc);

        // Model events
        $(this.model).on(
            'doorStylesLoaded',
            $.proxy(this.doorStylesLoaded, this)
        );

        // Door style change
        this.$selectDoorStyleOptions.on(
            'change',
            $.proxy(this.doorStyleOptionsChange, this)
        );
        this.$selectRaisedPanelOptions.on(
            'change',
            $.proxy(this.raisedPanelOptionsChange, this)
        );
        this.$selectAppliedMouldingOptions.on(
            'change',
            $.proxy(this.appliedMouldingOptionsChange, this)
        );
        this.$selectMiterMouldingOptions.on(
            'change',
            $.proxy(this.miterMouldingOptionsChange, this)
        );

        // Input change events
        this.$txtPanelsWide.on('change', $.proxy(this.txtInputChange, this));
        this.$txtPanelsHigh.on('change', $.proxy(this.txtInputChange, this));
        this.$txtTopRailWidth.on('change', $.proxy(this.txtInputChange, this));
        this.$txtBottomRailWidth.on(
            'change',
            $.proxy(this.txtInputChange, this)
        );
        this.$txtLeftStileWidth.on(
            'change',
            $.proxy(this.txtInputChange, this)
        );
        this.$txtRightStileWidth.on(
            'change',
            $.proxy(this.txtInputChange, this)
        );
        this.$txtCenterStileWidth.on(
            'change',
            $.proxy(this.txtInputChange, this)
        );
        this.$txtCenterRailWidth.on(
            'change',
            $.proxy(this.txtInputChange, this)
        );

        // Calculate button event
        this.$buttonCalc.on('click', $.proxy(this.buttonCalcClickPre, this));

        // Initialize form
        this.model.queryDoorStyle();
    },

    // -------------------------
    // Parsing
    // -------------------------

    _determineDoorType: function(selectedDoorStyle) {
        this.selectedDoorStyle = selectedDoorStyle;

        if (
            this.selectedDoorStyle.ConstructionType.toLowerCase() === this.SLAB
        ) {
            this.selectedDoorStyle.type = this.SLAB;
            return;
        }

        var endsOnOneOrTwo = this.selectedDoorStyle.ProductId.match(
            this.regexEndsWithOneOrTwo
        );
        if (endsOnOneOrTwo !== null) {
            endsOnOneOrTwo = endsOnOneOrTwo[0].slice(-1);
            if (endsOnOneOrTwo == 1 || endsOnOneOrTwo == 2) {
                endsOnOneOrTwo = true;
            } else {
                endsOnOneOrTwo = null;
            }
        }

        if (this.selectedDoorStyle.ProductId.indexOf('W4') === 0) {
            this.selectedDoorStyle.type = this.MITER;

            if (
                this.selectedDoorStyle.ProductId.match(
                    this.regexMiterWithRaisedPanelDoorStyle
                ) &&
                endsOnOneOrTwo === null
            ) {
                this.selectedDoorStyle.type = this.MITER_WITH_RAISED_PANEL_DOOR_STYLE;
            }
            return;
        }

        var matchAppliedMoulding = this.selectedDoorStyle.ProductId.match(
            this.regexAppliedMoulding
        );
        if (matchAppliedMoulding !== null) {
            this.selectedDoorStyle.type = this.APPLIED_MOULDING;

            var matchAppliedMouldingRp = this.selectedDoorStyle.ProductId.match(
                this.regexAppliedMouldingWithRaisedPanelDoorStyle
            );
            if (matchAppliedMouldingRp !== null && endsOnOneOrTwo === null) {
                this.selectedDoorStyle.type = this.APPLIED_MOULDING_WITH_RAISED_PANEL_DOOR_STYLE;
            }
            return;
        }

        var matchCopeAndStick = this.selectedDoorStyle.ProductId.match(
            this.regexCopeAndStick
        );
        if (matchCopeAndStick !== null) {
            this.selectedDoorStyle.type = this.STANDARD_COPE_AND_STICK;

            var matchCopeAndStickAlt = this.selectedDoorStyle.ProductId.match(
                this.regexAlternateAppliedMouldingWithRaisedPanelDoorStyle
            );
            if (matchCopeAndStickAlt !== null && endsOnOneOrTwo === null) {
                this.selectedDoorStyle.type = this.STANDARD_COPE_AND_STICK_WITH_RAISED_PANEL_DOOR_STYLE;
            }
            return;
        }
    },

    // -------------------------
    // Model Event handlers
    // -------------------------

    doorStylesLoaded: function(e) {
        this.resultDoorStyle = e.resultDoorStyle;
        this._buildDoorOptions();
        this.doorStyleOptionsChange();
    },

    miterMouldingsLoaded: function(e) {
        this.resultMiterMouldings = e.resultMiterMouldings;
        this._buildMiterMouldingOptions();
        this.postHandleMiter();
    },

    raisedPanelProfilesLoaded: function(e) {
        this.resultRaisedPanelProfiles = e.resultRaisedPanelProfiles;
        this._buildRaisedPanelProfileOptions();

        switch (e.queryCaller) {
            case this.APPLIED_MOULDING_WITH_RAISED_PANEL_DOOR_STYLE:
                this.postHandleAppliedMouldingWithRaisedPanelDoorStyle();
                break;
            case this.MITER_WITH_RAISED_PANEL_DOOR_STYLE:
                this.postHandleMiterWithRaisedPanelDoorStyle();
                break;
            case this.STANDARD_COPE_AND_STICK_WITH_RAISED_PANEL_DOOR_STYLE:
                this.postHandleStandardCopeAndStickWithRaisedPanelDoorStyle();
                break;
        }
    },

    appliedMouldingsLoaded: function(e) {
        this.resultAppliedMouldings = e.resultAppliedMouldings;
        this._buildAppliedMouldingOptions();
    },

    minValuesLoaded: function(e) {
        this.resultMinValues = e.resultMinValues;

        switch (e.queryCaller) {
            case this.STANDARD_COPE_AND_STICK:
                this.postHandleStandardCopeAndStick(e);
                break;

            case this.APPLIED_MOULDING:
                this.postHandleAppliedMoulding(e);
                break;
        }
    },

    // -------------------------
    // Form Event handlers
    // -------------------------

    txtInputChange: function(e) {
        this._resetWidthHeight();
    },

    buttonCalcClickPre: function(e) {
        e.preventDefault();

        $(this.model).one(
            'minValuesLoaded',
            $.proxy(this.btnCalcClickPost, this)
        );
        this.model.getMinValues(
            this.selectedDoorStyle.ProdIdentity,
            [1, 2],
            'btn-calc'
        );
    },

    btnCalcClickPost: function(e) {
        // Plain fetch
        this.calculationValues = {
            panelsWide: Number(
                this.$txtPanelsWide.val() !== '' ? this.$txtPanelsWide.val() : 0
            ),
            panelsHigh: Number(
                this.$txtPanelsHigh.val() !== '' ? this.$txtPanelsHigh.val() : 0
            ),
            raisedPanelProfileWidth: Number(
                this.$selectRaisedPanelOptions.val() !== ''
                    ? this.$selectRaisedPanelOptions.val()
                    : 0
            ),
            centerStileWidth: Number(
                this.$txtCenterStileWidth.val() !== ''
                    ? this.$txtCenterStileWidth.val()
                    : 0
            ),
            centerRailWidth: Number(
                this.$txtCenterRailWidth.val() !== ''
                    ? this.$txtCenterStileWidth.val()
                    : 0
            ),
            defaultMinWidth: Number(
                e.resultMinValues['pfid-2'][0].MinValue !== ''
                    ? e.resultMinValues['pfid-2'][0].MinValue
                    : 0
            ),
            calculatedMinWidth: 0,
            defaultMinHeight: Number(
                e.resultMinValues['pfid-1'][0].MinValue !== ''
                    ? e.resultMinValues['pfid-1'][0].MinValue
                    : 0
            ),
            calculatedMinHeight: 0,
            defaultOver: 0.5,
            appliedMouldingWidth: Number(
                this.$selectAppliedMouldingOptions.val() !== ''
                    ? this.$selectAppliedMouldingOptions.val()
                    : 0
            ),
            miterMouldingWidth: Number(
                this.$selectMiterMouldingOptions.val() !== ''
                    ? this.$selectMiterMouldingOptions.val()
                    : 0
            ),
            leftStileWidth: Number(
                this.$txtLeftStileWidth.val() !== ''
                    ? this.$txtLeftStileWidth.val()
                    : 0
            ),
            rightStileWidth: Number(
                this.$txtRightStileWidth.val() !== ''
                    ? this.$txtRightStileWidth.val()
                    : 0
            ),
            topRailWidth: Number(
                this.$txtTopRailWidth.val() !== ''
                    ? this.$txtTopRailWidth.val()
                    : 0
            ),
            bottomRailWidth: Number(
                this.$txtBottomRailWidth.val() !== ''
                    ? this.$txtBottomRailWidth.val()
                    : 0
            ),
        };

        // Value calculations
        this.calculationValues.raisedPanelProfileWidth =
            this.calculationValues.raisedPanelProfileWidth > 0
                ? this.calculationValues.raisedPanelProfileWidth / 25.4
                : 0;
        this.calculationValues.miterMouldingWidth =
            this.calculationValues.miterMouldingWidth > 0
                ? this.calculationValues.miterMouldingWidth / 25.4
                : 0;
        this.calculationValues.appliedMouldingWidth =
            this.calculationValues.appliedMouldingWidth > 0
                ? this.calculationValues.appliedMouldingWidth / 25.4
                : 0;
        this.calculationValues.defaultMinWidth =
            this.calculationValues.defaultMinWidth > 0
                ? this.defaultCalculation(
                      this.calculationValues.defaultMinWidth
                  )
                : 0;
        this.calculationValues.defaultMinHeight =
            this.calculationValues.defaultMinHeight > 0
                ? this.defaultCalculation(
                      this.calculationValues.defaultMinHeight
                  )
                : 0;

        // Calculate min width and min height
        var txtMinWidth = 0;
        var txtMinHeight = 0;

        if (this.selectedDoorStyle.type.toLowerCase() === this.SLAB) {
            txtMinWidth = this.calculationValues.defaultMinWidth;
            txtMinHeight = this.calculationValues.defaultMinHeight;
        } else {
            // Calculate min width
            this.calculationValues.calculatedMinWidth =
                this.calculationValues.leftStileWidth +
                this.calculationValues.rightStileWidth +
                this.calculationValues.appliedMouldingWidth * 2 +
                this.calculationValues.miterMouldingWidth * 2 +
                this.calculationValues.raisedPanelProfileWidth * 2 +
                this.calculationValues.defaultOver;

            if (this.calculationValues.panelsWide > 1) {
                this.calculationValues.panelsWide =
                    this.calculationValues.panelsWide - 1;

                this.calculationValues.calculatedMinWidth +=
                    this.calculationValues.panelsWide *
                    (this.calculationValues.appliedMouldingWidth * 2 +
                        this.calculationValues.miterMouldingWidth * 2 +
                        this.calculationValues.raisedPanelProfileWidth * 2 +
                        this.calculationValues.centerStileWidth +
                        this.calculationValues.defaultOver);
            }

            txtMinWidth =
                this.calculationValues.defaultMinWidth >
                this.calculationValues.calculatedMinWidth
                    ? this.calculationValues.defaultMinWidth
                    : this.calculationValues.calculatedMinWidth;

            // Calculate min height
            this.calculationValues.calculatedMinHeight =
                this.calculationValues.topRailWidth +
                this.calculationValues.bottomRailWidth +
                this.calculationValues.appliedMouldingWidth * 2 +
                this.calculationValues.miterMouldingWidth * 2 +
                this.calculationValues.raisedPanelProfileWidth * 2 +
                this.calculationValues.defaultOver;

            if (this.calculationValues.panelsHigh > 1) {
                this.calculationValues.panelsHigh =
                    this.calculationValues.panelsHigh - 1;

                this.calculationValues.calculatedMinHeight +=
                    this.calculationValues.panelsHigh *
                    (this.calculationValues.appliedMouldingWidth * 2 +
                        this.calculationValues.miterMouldingWidth * 2 +
                        this.calculationValues.raisedPanelProfileWidth * 2 +
                        this.calculationValues.centerStileWidth +
                        this.calculationValues.defaultOver);
            }

            txtMinHeight =
                this.calculationValues.defaultMinHeight >
                this.calculationValues.calculatedMinHeight
                    ? this.calculationValues.defaultMinHeight
                    : this.calculationValues.calculatedMinHeight;
        }

        console.log(txtMinWidth, txtMinHeight);
        this.$txtMinWidth.html(txtMinWidth.toFixed(4));
        this.$txtMinHeight.html(txtMinHeight.toFixed(4));

        //this._showCalculationValues();
    },

    doorStyleOptionsChange: function() {
        var $selected = this.$selectDoorStyleOptions.find('option:selected');
        var val = $selected.val();
        this._determineDoorType(this.resultDoorStyle['key-' + val]);

        this._resetForm();

        switch (this.selectedDoorStyle.type.toLowerCase()) {
            case this.SLAB:
                this.preHandleSlab();
                break;
            case this.MITER:
                this.preHandleMiter();
                break;
            case this.MITER_WITH_RAISED_PANEL_DOOR_STYLE:
                this.preHandleMiterWithRaisedPanelDoorStyle();
                break;
            case this.APPLIED_MOULDING:
                this.preHandleAppliedMoulding();
                break;
            case this.APPLIED_MOULDING_WITH_RAISED_PANEL_DOOR_STYLE:
                this.preHandleAppliedMouldingWithRaisedPanelDoorStyle();
                break;
            case this.STANDARD_COPE_AND_STICK:
                this.preHandleStandardCopeAndStick();
                break;
            case this.STANDARD_COPE_AND_STICK_WITH_RAISED_PANEL_DOOR_STYLE:
                this.preHandleStandardCopeAndStickWithRaisedPanelDoorStyle();
                break;
        }
    },

    raisedPanelOptionsChange: function(e) {
        this._resetWidthHeight();

        var raisedPanel = !this.$selectRaisedPanelOptions.prop('disabled');
        var appliedMoulding = !this.$selectAppliedMouldingOptions.prop(
            'disabled'
        );
        var miterMoulding = !this.$selectMiterMouldingOptions.prop('disabled');

        if (!miterMoulding && !appliedMoulding) {
            this._enableElement(this.$buttonCalc);
            this.$buttonCalc.focus();
        } else {
            if (
                !miterMoulding &&
                appliedMoulding &&
                (this.$selectAppliedMouldingOptions.val() === null ||
                    this.$selectAppliedMouldingOptions.val() === '')
            ) {
                this.$selectAppliedMouldingOptions.focus();
            } else {
                if (
                    !appliedMoulding &&
                    miterMoulding &&
                    (this.$selectMiterMouldingOptions.val() === null ||
                        this.$selectMiterMouldingOptions.val() === '')
                ) {
                    this.$selectMiterMouldingOptions.focus();
                } else {
                    this._enableElement(this.$buttonCalc);
                    this.$buttonCalc.focus();
                }
            }
        }
    },

    appliedMouldingOptionsChange: function(e) {
        this._resetWidthHeight();

        var raisedPanel = !this.$selectRaisedPanelOptions.prop('disabled');
        var appliedMoulding = !this.$selectAppliedMouldingOptions.prop(
            'disabled'
        );
        var miterMoulding = !this.$selectMiterMouldingOptions.prop('disabled');

        if (!miterMoulding && !appliedMoulding) {
            this._enableElement(this.$buttonCalc);
            this.$buttonCalc.focus();
        } else {
            if (
                !miterMoulding &&
                raisedPanel &&
                (this.$selectRaisedPanelOptions.val() === null ||
                    this.$selectRaisedPanelOptions.val() === '')
            ) {
                this.$selectRaisedPanelOptions.focus();
            } else {
                if (
                    !raisedPanel &&
                    miterMoulding &&
                    (this.$selectMiterMouldingOptions.val() === null ||
                        this.$selectMiterMouldingOptions.val() === '')
                ) {
                    this.$selectMiterMouldingOptions.focus();
                } else {
                    this._enableElement(this.$buttonCalc);
                    this.$buttonCalc.focus();
                }
            }
        }
    },

    // This select change is slightly different
    miterMouldingOptionsChange: function() {
        this._resetWidthHeight();

        var raisedPanel = !this.$selectRaisedPanelOptions.prop('disabled');
        var appliedMoulding = !this.$selectAppliedMouldingOptions.prop(
            'disabled'
        );
        var miterMoulding = !this.$selectMiterMouldingOptions.prop('disabled');

        if (!raisedPanel && !appliedMoulding) {
            this._enableElement(this.$buttonCalc);
            this.$buttonCalc.focus();
        } else {
            if (
                !raisedPanel &&
                appliedMoulding &&
                (this.$selectAppliedMouldingOptions.val() === null ||
                    this.$selectAppliedMouldingOptions.val() === '')
            ) {
                this.$selectAppliedMouldingOptions.focus();
            } else {
                if (
                    !appliedMoulding &&
                    raisedPanel &&
                    (this.$selectRaisedPanelOptions.val() === null ||
                        this.$selectRaisedPanelOptions.val() === '')
                ) {
                    this.$selectRaisedPanelOptions.focus();
                } else {
                    this._enableElement(this.$buttonCalc);
                    this.$buttonCalc.focus();
                }
            }
        }
    },

    _resetWidthHeight: function() {
        this.$txtMinWidth.html('');
        this.$txtMinHeight.html('');
    },

    // -------------------------
    // PRE Handlers (before data is in)
    // -------------------------

    preHandleSlab: function() {
        this._enableElement(this.$buttonCalc);
    },

    preHandleMiter: function() {
        if (this.resultMiterMouldings === undefined) {
            $(this.model).one(
                'miterMouldingsLoaded',
                $.proxy(this.miterMouldingsLoaded, this)
            );
            this.model.getMiterMouldings();
        } else {
            this.postHandleMiter();
        }
    },

    preHandleMiterWithRaisedPanelDoorStyle: function() {
        this.preHandleMiter();

        $(this.model).one(
            'raisedPanelProfilesLoaded',
            $.proxy(this.raisedPanelProfilesLoaded, this)
        );
        this.model.getRaisedPanelProfiles(
            this.MITER_WITH_RAISED_PANEL_DOOR_STYLE
        );
    },

    preHandleAppliedMoulding: function() {
        if (this.resultAppliedMouldings === undefined) {
            $(this.model).one(
                'appliedMouldingsLoaded',
                $.proxy(this.appliedMouldingsLoaded, this)
            );
            this.model.getAppliedMouldings();
        }

        $(this.model).one(
            'minValuesLoaded',
            $.proxy(this.minValuesLoaded, this)
        );
        this.model.getMinValues(
            this.selectedDoorStyle.ProdIdentity,
            [3, 4, 5, 6, 7, 8, 29, 30],
            this.APPLIED_MOULDING
        );
    },

    preHandleAppliedMouldingWithRaisedPanelDoorStyle: function() {
        this.preHandleAppliedMoulding();

        $(this.model).one(
            'raisedPanelProfilesLoaded',
            $.proxy(this.raisedPanelProfilesLoaded, this)
        );
        this.model.getRaisedPanelProfiles(
            this.APPLIED_MOULDING_WITH_RAISED_PANEL_DOOR_STYLE
        );
    },

    preHandleStandardCopeAndStick: function(enableButton) {
        if (enableButton === undefined) {
            enableButton = true;
        }

        if (enableButton) {
            this._enableElement(this.$buttonCalc);
        }
        $(this.model).one(
            'minValuesLoaded',
            $.proxy(this.minValuesLoaded, this)
        );
        this.model.getMinValues(
            this.selectedDoorStyle.ProdIdentity,
            [3, 4, 5, 6, 7, 8, 29, 30],
            this.STANDARD_COPE_AND_STICK
        );
    },

    preHandleStandardCopeAndStickWithRaisedPanelDoorStyle: function() {
        this.preHandleStandardCopeAndStick(false);

        $(this.model).one(
            'raisedPanelProfilesLoaded',
            $.proxy(this.raisedPanelProfilesLoaded, this)
        );
        this.model.getRaisedPanelProfiles(
            this.STANDARD_COPE_AND_STICK_WITH_RAISED_PANEL_DOOR_STYLE
        );
    },

    // -------------------------
    // POST Handlers (after data is in)
    // -------------------------

    postHandleSlab: function() {
        this._enableElement(this.$buttonCalc);
    },

    postHandleMiter: function() {
        this._enableElement(this.$selectMiterMouldingOptions);
    },

    postHandleMiterWithRaisedPanelDoorStyle: function() {
        this._enableElement(this.$selectRaisedPanelOptions);
    },

    postHandleAppliedMoulding: function(e) {
        var minValues = e.resultMinValues;
        var formSetup = [];

        formSetup.push({ enable: true, selector: this.selectDoorStyleOptions });
        formSetup.push({
            enable: true,
            selector: this.selectAppliedMouldingOptions,
        });

        if (minValues['pfid-3'] !== undefined) {
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-3',
                    this.txtTopRailWidth
                )
            );
        } else {
            formSetup.push({ enable: false, selector: this.txtTopRailWidth });
        }

        if (minValues['pfid-5'] !== undefined) {
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-5',
                    this.txtBottomRailWidth
                )
            );
        } else {
            formSetup.push({
                enable: false,
                selector: this.txtBottomRailWidth,
            });
        }

        if (minValues['pfid-6'] !== undefined) {
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-6',
                    this.txtLeftStileWidth
                )
            );
        } else {
            formSetup.push({ enable: false, selector: this.txtLeftStileWidth });
        }

        if (minValues['pfid-8'] !== undefined) {
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-8',
                    this.txtRightStileWidth
                )
            );
        } else {
            formSetup.push({
                enable: false,
                selector: this.txtRightStileWidth,
            });
        }

        if (
            minValues['pfid-30'] !== undefined &&
            minValues['pfid-30'][0].MaxValue > 1
        ) {
            if (minValues['pfid-7'] !== undefined) {
                formSetup.push(
                    this._createDefaultFormSetup(
                        minValues,
                        'pfid-7',
                        this.txtCenterStileWidth
                    )
                );
            } else {
                formSetup.push({
                    enable: false,
                    selector: this.txtCenterStileWidth,
                });
            }
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-30',
                    this.txtPanelsWide,
                    false
                )
            );
        } else {
            formSetup.push({
                enable: false,
                selector: this.txtCenterStileWidth,
            });
            formSetup.push({ enable: false, selector: this.txtPanelsWide });
        }

        if (
            minValues['pfid-29'] !== undefined &&
            minValues['pfid-29'][0].MaxValue > 1
        ) {
            if (minValues['pfid-4']) {
                formSetup.push(
                    this._createDefaultFormSetup(
                        minValues,
                        'pfid-4',
                        this.txtCenterRailWidth
                    )
                );
            } else {
                formSetup.push({
                    enable: false,
                    selector: this.txtCenterRailWidth,
                });
            }
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-29',
                    this.txtPanelsHigh,
                    false
                )
            );
        } else {
            formSetup.push({
                enable: false,
                selector: this.txtCenterRailWidth,
            });
            formSetup.push({ enable: false, selector: this.txtPanelsHigh });
        }

        this._setupForm(formSetup);
    },

    postHandleAppliedMouldingWithRaisedPanelDoorStyle: function() {
        this._enableElement(this.$selectRaisedPanelOptions);
    },

    postHandleStandardCopeAndStick: function(e) {
        var minValues = e.resultMinValues;
        var formSetup = [];

        formSetup.push({ enable: true, selector: this.selectDoorStyleOptions });

        if (minValues['pfid-3'] !== undefined) {
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-3',
                    this.txtTopRailWidth
                )
            );
        } else {
            formSetup.push({ enable: false, selector: this.txtTopRailWidth });
        }

        if (minValues['pfid-5'] !== undefined) {
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-5',
                    this.txtBottomRailWidth
                )
            );
        } else {
            formSetup.push({
                enable: false,
                selector: this.txtBottomRailWidth,
            });
        }

        if (minValues['pfid-6'] !== undefined) {
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-6',
                    this.txtLeftStileWidth
                )
            );
        } else {
            formSetup.push({ enable: false, selector: this.txtLeftStileWidth });
        }

        if (minValues['pfid-8'] !== undefined) {
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-8',
                    this.txtRightStileWidth
                )
            );
        } else {
            formSetup.push({
                enable: false,
                selector: this.txtRightStileWidth,
            });
        }

        if (
            minValues['pfid-30'] !== undefined &&
            minValues['pfid-30'][0].MaxValue > 1
        ) {
            if (minValues['pfid-7'] !== undefined) {
                formSetup.push(
                    this._createDefaultFormSetup(
                        minValues,
                        'pfid-7',
                        this.txtCenterStileWidth
                    )
                );
            } else {
                formSetup.push({
                    enable: false,
                    selector: this.txtCenterStileWidth,
                });
            }
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-30',
                    this.txtPanelsWide,
                    false
                )
            );
        } else {
            formSetup.push({
                enable: false,
                selector: this.txtCenterStileWidth,
            });
            formSetup.push({ enable: false, selector: this.txtPanelsWide });
        }

        if (
            minValues['pfid-29'] !== undefined &&
            minValues['pfid-29'][0].MaxValue > 1
        ) {
            if (minValues['pfid-4']) {
                formSetup.push(
                    this._createDefaultFormSetup(
                        minValues,
                        'pfid-4',
                        this.txtCenterRailWidth
                    )
                );
            } else {
                formSetup.push({
                    enable: false,
                    selector: this.txtCenterRailWidth,
                });
            }
            formSetup.push(
                this._createDefaultFormSetup(
                    minValues,
                    'pfid-29',
                    this.txtPanelsHigh,
                    false
                )
            );
        } else {
            formSetup.push({
                enable: false,
                selector: this.txtCenterRailWidth,
            });
            formSetup.push({ enable: false, selector: this.txtPanelsHigh });
        }

        this._setupForm(formSetup);
    },

    postHandleStandardCopeAndStickWithRaisedPanelDoorStyle: function() {
        this._enableElement(this.$selectRaisedPanelOptions);
    },

    // -------------------------
    // Calculations
    // -------------------------

    defaultCalculation: function(value) {
        return Math.round(value / 25.4 / 0.0625) * 0.0625;
    },

    // -------------------------
    // Private
    // -------------------------

    _createDefaultFormSetup: function(minValues, key, selector, calculate) {
        return {
            value: Number(
                calculate === undefined || calculate
                    ? this.defaultCalculation(minValues[key][0].DefaultValue)
                    : minValues[key][0].DefaultValue
            ),
            minVal: Number(
                calculate === undefined || calculate
                    ? this.defaultCalculation(minValues[key][0].MinValue)
                    : minValues[key][0].MinValue
            ),
            maxVal: Number(
                calculate === undefined || calculate
                    ? this.defaultCalculation(minValues[key][0].MaxValue)
                    : minValues[key][0].MaxValue
            ),
            enable: true,
            selector: selector,
        };
    },

    _setupForm: function(formSetup) {
        for (var i in formSetup) {
            var $formElement = $(formSetup[i].selector);

            // Elememt enabled?
            if (formSetup[i].enable !== undefined) {
                if (formSetup[i].enable) {
                    this._enableElement($formElement);
                }
                if (!formSetup[i].enable) {
                    this._disableElement($formElement);
                }
            }

            if (formSetup[i].minVal !== undefined) {
                $formElement.attr('min', formSetup[i].minVal);
            }
            if (formSetup[i].maxVal !== undefined) {
                $formElement.attr('max', formSetup[i].maxVal);
            }

            if (
                formSetup[i].minVal !== undefined ||
                formSetup[i].maxVal !== undefined
            ) {
                if (
                    formSetup[i].selector === 'input#panels-wide' ||
                    formSetup[i].selector === 'input#panels-high'
                ) {
                    $formElement.attr('step', '1');
                } else {
                    $formElement.attr('step', '0.05');
                }
            }

            if (formSetup[i].value !== undefined) {
                $formElement.val(formSetup[i].value);
            }

            this._validateSet(formSetup[i].selector);
        }

        //this._showInfo(formSetup);
        this._missingFieldSetup();
    },

    _showInfo: function(formSetup) {
        var $calcDoorSize = $('div#calcDoorSize');

        $calcDoorSize.find('div#info').remove();
        var $info = $('<div />')
            .attr('id', 'info')
            .css({
                padding: '10px 20px',
                background: '#ffffff',
                marginBottom: '20px',
            });

        if ($info.length > 0) {
            $info.children().remove();

            var text = [];
            var line =
                '<b>Form calculation type: ' +
                this.selectedDoorStyle.type +
                '</b>';
            text.push(line);

            for (var i in formSetup) {
                var line = formSetup[i].selector;
                if (formSetup[i].enable !== undefined) {
                    line += formSetup[i].enable ? ' enable' : ' disable';
                    line += ' |';
                }
                if (formSetup[i].value !== undefined) {
                    line += ' value: ' + formSetup[i].value + ' |';
                }
                if (formSetup[i].minVal !== undefined) {
                    line += ' validate min val: ' + formSetup[i].minVal + ' |';
                }
                if (formSetup[i].maxVal !== undefined) {
                    line += ' validate max val: ' + formSetup[i].maxVal + ' |';
                }
                text.push(line);
            }
            text = '<p>' + text.join('<br />') + '</p>';

            $info.append(text);
        }

        $calcDoorSize.prepend($info);
    },

    _showCalculationValues: function() {
        var $info = $('div#info');

        if ($info.length > 0) {
            var text = [];

            text.push('<br /><b>Calculation values</b>');

            text.push(
                'Panels wide: (pw): ' + this.calculationValues.panelsWide
            );
            text.push(
                'Panels high: (ph): ' + this.calculationValues.panelsHigh
            );
            text.push(
                'Raised panel profiles width: (rp): ' +
                    this.calculationValues.raisedPanelProfileWidth
            );
            text.push(
                'Center stile width: (cs): ' +
                    this.calculationValues.centerStileWidth
            );
            text.push(
                'Center rail width: (cr): ' +
                    this.calculationValues.centerRailWidth
            );
            text.push(
                'Default min width: (dfminwidth): ' +
                    this.calculationValues.defaultMinWidth
            );
            text.push(
                'Calculated min width: (calcminwidth): ' +
                    this.calculationValues.calculatedMinWidth
            );
            text.push(
                'Calculated default min height: (dfminheight): ' +
                    this.calculationValues.defaultMinHeight
            );
            text.push(
                'Calculated min height: (calcminheight): ' +
                    this.calculationValues.calculatedMinHeight
            );
            text.push(
                'Default over: (dfltover): ' +
                    this.calculationValues.defaultOver
            );
            text.push(
                'Applied moulding width: (am): ' +
                    this.calculationValues.appliedMouldingWidth
            );
            text.push(
                'Miter moulding width: (mtr): ' +
                    this.calculationValues.miterMouldingWidth
            );
            text.push(
                'Left stile width: (lft): ' +
                    this.calculationValues.leftStileWidth
            );
            text.push(
                'Right stile width: (rht): ' +
                    this.calculationValues.rightStileWidth
            );
            text.push(
                'Top rail width: (tr): ' + this.calculationValues.topRailWidth
            );
            text.push(
                'Bottom rail width: (br): ' +
                    this.calculationValues.bottomRailWidth
            );

            text = '<p>' + text.join('<br />') + '</p>';

            $info.append(text);
        }
    },

    _resetForm: function() {
        var formSetup = [];

        this._resetWidthHeight();

        //formSetup.push({enable: false, selector: this.selectDoorStyleOptions});
        formSetup.push({
            enable: false,
            selector: this.selectRaisedPanelOptions,
        });
        formSetup.push({
            enable: false,
            selector: this.selectAppliedMouldingOptions,
        });
        formSetup.push({
            enable: false,
            selector: this.selectMiterMouldingOptions,
        });
        formSetup.push({ enable: false, selector: this.txtPanelsWide });
        formSetup.push({ enable: false, selector: this.txtPanelsHigh });
        formSetup.push({ enable: false, selector: this.txtTopRailWidth });
        formSetup.push({ enable: false, selector: this.txtBottomRailWidth });
        formSetup.push({ enable: false, selector: this.txtLeftStileWidth });
        formSetup.push({ enable: false, selector: this.txtRightStileWidth });
        formSetup.push({ enable: false, selector: this.txtCenterStileWidth });
        formSetup.push({ enable: false, selector: this.txtCenterRailWidth });
        formSetup.push({ enable: false, selector: this.txtMinWidth });
        formSetup.push({ enable: false, selector: this.txtMinHeight });
        formSetup.push({ enable: false, selector: this.buttonCalc });

        this._setupForm(formSetup);
    },

    _validateSet: function(field) {
        for (var i in this.elementSelectors) {
            if (this.elementSelectors[i].element === field) {
                this.elementSelectors[i].set = true;
            }
        }
    },

    _missingFieldSetup: function() {
        for (var i in this.elementSelectors) {
            if (!this.elementSelectors[i].set) {
                //console.log(this.elementSelectors[i].element, 'is not built by the setupForm');
            }
            this.elementSelectors[i].set = false;
        }
    },

    _buildRaisedPanelProfileOptions: function() {
        this.$selectRaisedPanelOptions.find('option').remove();
        for (var i in this.resultRaisedPanelProfiles) {
            var data = this.resultRaisedPanelProfiles[i];
            $option = $('<option />')
                .val(data.Width)
                .text(data.Description);
            this.$selectRaisedPanelOptions.prepend($option);
        }

        var $selected = $('<option />')
            .attr('selected', 'selected')
            .val(null)
            .text('None');
        this.$selectRaisedPanelOptions.prepend($selected);
    },

    _buildAppliedMouldingOptions: function() {
        this.$selectAppliedMouldingOptions.find('option').remove();
        for (var i in this.resultAppliedMouldings) {
            var data = this.resultAppliedMouldings[i];
            $option = $('<option />')
                .val(data.Width)
                .text(data.Description);
            this.$selectAppliedMouldingOptions.prepend($option);
        }

        var $selected = $('<option />')
            .attr('selected', 'selected')
            .val(null)
            .text('None');
        this.$selectAppliedMouldingOptions.prepend($selected);
    },

    _buildMiterMouldingOptions: function() {
        this.$selectMiterMouldingOptions.find('option').remove();
        for (var i in this.resultMiterMouldings) {
            var data = this.resultMiterMouldings[i];
            $option = $('<option />')
                .val(data.Width)
                .text(data.Description);
            this.$selectMiterMouldingOptions.prepend($option);
        }

        var $selected = $('<option />')
            .attr('selected', 'selected')
            .val(null)
            .text('None');
        this.$selectMiterMouldingOptions.prepend($selected);
    },

    _buildDoorOptions: function() {
        this.$selectDoorStyleOptions.find('option').remove();
        for (var i in this.resultDoorStyle) {
            var data = this.resultDoorStyle[i];
            $option = $('<option />')
                .val(data.ProdIdentity)
                .text(data.ProductId);
            if (this.hash) {
                if (data.ProductId.toLowerCase() === this.hash) {
                    $option.attr('selected', 'selected');
                }
            } else {
                if (i === 0) {
                    $option.attr('selected', 'selected');
                }
            }
            this.$selectDoorStyleOptions.append($option);
        }
    },

    _enableElement: function($element) {
        $element.removeAttr('disabled');
    },

    _disableElement: function($element) {
        $element.attr('disabled', 'disabled');
        $element.val('');
    },
});
